<template>
  <b-card :title="incidenceTitle">
    <b-row class="mb-1">
      <!-- TÍTOL -->
      <b-col v-if="editMode" cols="12" md="6">
        <b-form-group :label="$t('Títol')" label-for="title">
          <b-form-input id="title" v-model="title" />
        </b-form-group>
      </b-col>

      <!-- DATA -->
      <b-col cols="12" :lg="editMode ? 6 : 12">
        <b-form-group :label="$t('Data opertura')" label-for="createDate">
          <flat-pickr
            v-if="editMode"
            v-model="createDate"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
          />
          <div v-else id="createDate">
            {{ incidenceDate }}
          </div>
        </b-form-group>
      </b-col>

      <!-- ESTAT -->
      <b-col cols="12" :lg="editMode ? 6 : 12">
        <b-form-group :label="$t('Estat')" label-for="status">
          <div id="status">
            <v-select
              v-if="editMode"
              v-model="status"
              :options="bookingIncidenceStatusOptions"
              :reduce="(option) => option.value"
            />
            <div v-else>
              <b-badge-dropdown
                :badge-text="incidenceStatusName"
                :badge-variant="incidenceStatusVariant"
                :dropdown-options="bookingIncidenceStatusOptions"
                @selected="updateIncidenceStatus(incidence, $event)"
              />
            </div>
          </div>
        </b-form-group>
      </b-col>

      <!-- RESERVA -->
      <b-col cols="12" :lg="editMode ? 6 : 12">
        <b-form-group :label="$t('Reserva')" label-for="booking">
          <div id="booking">
            <v-select
              v-if="editMode"
              v-model="booking"
              :options="bookingOptions"
              :reduce="(option) => option.value"
              :clearable="false"
              @search="fetchBookingOptions"
              @option:selecting="onBookingSelected"
            >
              <template slot="no-options">
                Escriu el localitzador de la reserva...
              </template>
              <template #option="option">
                <div>{{ option.localizator }} a {{ option.accommodation }}</div>
                <div>
                  <small>
                    {{ option.client }}
                  </small>
                </div>
              </template>
            </v-select>
            <div v-else>
              {{ incidenceBooking }}
            </div>
          </div>
        </b-form-group>
      </b-col>

      <!-- COMENTARIS -->
      <b-col cols="12" class="mb-1">
        <b-form-group :label="$t('Comentaris')" label-for="comments">
          <b-overlay :show="loading" variant="white" :opacity="0.8">
            <!-- TIMELINE DE COMENTARIS -->
            <incidence-comment
              v-for="incidenceComment in incidenceComments"
              :key="`incidence-comment-${incidenceComment.uuid}`"
              :comment="incidenceComment"
              :can-delete="canDeleteComment(incidenceComment)"
              class="my-1"
            />

            <b-button
              variant="primary"
              v-if="canAddComments || editMode"
              @click="onAddCommentClicked"
            >
              Afegeix un comentari
            </b-button>
          </b-overlay>
        </b-form-group>
      </b-col>

      <!-- TAGS -->
      <b-col cols="12">
        <b-form-group :label="$t('Etiquetes')" label-for="tags">
          <div id="tags">
            <b-form-tags
              v-if="editMode"
              v-model="tags"
              input-id="tags-separators"
              separator=",;."
              placeholder="Introdueix les noves etiquetes separades per espai o coma"
              add-button-text="Afegeix"
              remove-on-delete
            />
            <template v-else>
              <div v-if="incidenceTags.length">
                <b-badge
                  v-for="(tag, index) in incidenceTags"
                  :key="`tag-${index}`"
                  class="mr-50"
                  variant="primary"
                >
                  {{ tag }}
                </b-badge>
              </div>
              <span v-else>
                {{ $t("Sense etiquetes") }}
              </span>
            </template>
          </div>
        </b-form-group>
      </b-col>

      <!-- NEW INCIDENCE COMMENTMODAL -->
      <b-modal
        id="new-incidence-comment-modal"
        size="lg"
        :title="$t('Nou comentari')"
        centered
        hide-footer
      >
        <new-incidence-comment-form
          :incidence="incidence"
          @added="onCommentAdded"
          @cancel="onAddCommentCancel"
        />
      </b-modal>

      <!-- CLOSE INCIDENCE MODAL -->
      <b-modal
        id="close-incidence-modal"
        size="lg"
        :title="$t('Tancar incidència')"
        centered
        hide-footer
      >
        <close-incidence-form
          :incidence="incidence"
          @close-incidence-close="onCloseIncidenceClose"
        />
      </b-modal>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-between justify-content-sm-end">
      <b-col v-if="editMode" cols="6" sm="auto">
        <b-button block variant="light" @click="editMode = false">
          Cancel·la
        </b-button>
      </b-col>
      <b-col v-if="editMode" cols="6" sm="auto">
        <b-button block variant="primary" @click="save"> Guarda </b-button>
      </b-col>
      <b-col v-if="!editMode" cols="12" sm="auto">
        <b-button block variant="primary" @click="editMode = true">
          Edita
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTags,
  BBadge,
  BModal,
} from "bootstrap-vue";
import {
  formatDateObjectToDatabaseDateTime,
  formatDateStringToDate,
} from "@/utils/formatters";
import vSelect from "vue-select";
import { bookingIncidenceStatusOptions } from "@/utils/select-options";
import _debounce from "lodash/debounce";
import {
  getBookingIncidenceStatusName,
  getBookingIncidenceStatusColor,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import BBadgeDropdown from "@foravila-core/components/b-badge-dropdown/BBadgeDropdown.vue";
import IncidenceComment from "@/views/incidences/incidence/components/IncidenceComment.vue";
import CloseIncidenceForm from "@/views/incidences/incidence/components/CloseIncidenceForm.vue";
import NewIncidenceCommentForm from "@/views/incidences/incidence/components/NewIncidenceCommentForm.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTags,
    BBadge,
    vSelect,
    BBadgeDropdown,
    IncidenceComment,
    CloseIncidenceForm,
    NewIncidenceCommentForm,
    flatPickr,
    BModal,
  },
  data() {
    return {
      loading: false,
      editMode: false,
      status: null,
      title: null,
      tags: [],
      createDate: null,
      booking: null,
      bookingOptions: [],
      bookingIncidenceStatusOptions,
    };
  },
  computed: {
    loadingIncidence() {
      return this.$store.getters["incidence/loading"];
    },
    incidence() {
      return this.$store.getters["incidence/incidence"];
    },
    cardTitle() {
      if (!this.incidence?.booking) return null;
      const title = [];
      const booking = this.incidence.booking;
      if (booking.accommodation?.name) title.push(booking.accommodation.name);
      if (booking.localizator) title.push(booking.localizator);
      if (booking.checkin && booking.checkout) {
        title.push(
          `${formatDateStringToDate(
            booking.checkin
          )} a ${formatDateStringToDate(booking.checkout)}`
        );
      }
      return title.join(" - ");
    },
    incidenceStatusName() {
      return (
        getBookingIncidenceStatusName(
          this.incidence?.status,
          this.$i18n.locale
        ) || this.$t("No disponible")
      );
    },
    incidenceStatusVariant() {
      return getBookingIncidenceStatusColor(this.incidence?.status);
    },
    incidenceTitle() {
      if (this.editMode) return null;
      return this.incidence?.title || this.$t("Incidència");
    },
    incidenceDate() {
      const formatting = {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(
        this.incidence?.createdAt,
        this.$i18n.locale,
        formatting
      );
    },
    incidenceBooking() {
      const text = [];
      if (this.incidence?.booking?.localizator)
        text.push(this.incidence?.booking.localizator);
      if (this.incidence?.booking?.accommodation?.name)
        text.push(this.incidence?.booking.accommodation.name);
      return text.length ? text.join(" a ") : null;
    },
    incidenceTags() {
      return this.incidence?.tags?.length ? this.incidence.tags.split(",") : [];
    },
    localizator() {
      return this.incidence?.booking?.localizator || null;
    },
    incidenceComments() {
      return this.incidence?.comments || [];
    },
    canAddComments() {
      return ["OPEN", "PENDING"].includes(this.incidence?.status);
    },
    serializedTags() {
      if (!this.tags.length) return null;
      return this.tags.join(",");
    },
  },
  watch: {
    editMode(edit) {
      if (edit) this.initInputs();
      else this.resetInputs();
    },
  },
  created() {
    this.fetchIncidence();
  },
  destroyed() {
    this.$store.dispatch("incidence/reset");
  },
  methods: {
    fetchIncidence() {
      this.$store.dispatch("app/setLoading", true);
      this.$store.dispatch("incidence/reset");
      const { incidenceUuid } = this.$route.params;
      this.$store
        .dispatch("incidence/fetchIncidence", incidenceUuid)
        .catch(() => {
          notifyError(
            this.$t("errors.fetchIncidence.title"),
            this.$t("errors.fetchIncidence.description")
          );
        })
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
        });
    },
    fetchBookingOptions(search, loading) {
      if (search.length) {
        loading(true);
        this.fetchBookings(loading, search, this);
      }
    },
    fetchBookings: _debounce((loading, search, innerThis) => {
      innerThis.$store
        .dispatch("bookings/fetchFilteredBookings", {
          pagination: true,
          itemsPerPage: 10,
          page: 1,
          searchText: search || "",
        })
        .then((response) => {
          innerThis.bookingOptions = innerThis.mapTableBookingsArray(response);
        })
        .catch(() =>
          notifyError(
            innerThis.$t("errors.fetchBookings.title"),
            innerThis.$t("errors.fetchBookings.description")
          )
        )
        .finally(() => loading(false));
    }, 500),
    mapTableBookingsArray(bookings) {
      if (!bookings.length) return [];
      return bookings.map((booking) => ({
        value: booking["@id"] || null,
        label: `${booking.localizator} a ${booking.accommodation?.name}`,
        accommodation: booking.accommodation?.name || null,
        localizator: booking.localizator || null,
        client: booking.client?.fullName || null,
      }));
    },
    onBookingSelected(selectedOption) {
      this.booking = selectedOption.value;
    },
    initInputs() {
      if (!this.incidence) return;
      this.status = this.incidence.status;
      this.title = this.incidence.title;
      this.tags = this.incidence.tags?.length
        ? this.incidence.tags.split(",")
        : [];
      if (this.incidence.booking) {
        this.booking = this.incidence.booking["@id"] || null;
        this.bookingOptions.push({
          value: this.incidence.booking["@id"] || null,
          label: `${this.incidence.booking.localizator} a ${this.incidence.booking.accommodation?.name}`,
          accommodation: this.incidence.booking.accommodation?.name || null,
          localizator: this.incidence.booking.localizator || null,
        });
      } else {
        this.booking = null;
        this.bookingOptions = [];
      }
      this.createDate = this.incidence.createdAt;
    },
    resetInputs() {
      this.status = null;
      this.title = null;
      this.tags = [];
      this.booking = null;
      this.bookingOptions = [];
      this.createDate = null;
    },
    canDeleteComment(comment) {
      if (this.editMode) return true;
      if (!comment?.postedAt || !this.canAddComments) return false;

      const postDate = this.$moment(comment.postedAt);
      const now = this.$moment();

      return now.isBefore(postDate.add(1, "day"), "hours");
    },
    updateIncidenceStatus(incidence, newStatus) {
      if (newStatus === incidence.status) return;
      if (newStatus !== "CLOSED") {
        this.loading = true;
        this.$store
          .dispatch("incidence/updateIncidence", {
            uuid: incidence.uuid,
            status: newStatus,
            updatedAt: formatDateObjectToDatabaseDateTime(new Date()),
          })
          .catch(() =>
            notifyError(
              "Error",
              "Hi ha hagut un error al intentar actualitzar l'estat de la incidència"
            )
          )
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$bvModal.show("close-incidence-modal");
      }
    },
    onCloseIncidenceClose() {
      this.$bvModal.hide("close-incidence-modal");
    },
    onAddCommentClicked() {
      this.$bvModal.show("new-incidence-comment-modal");
    },
    onCommentAdded() {
      this.$bvModal.hide("new-incidence-comment-modal");
    },
    onAddCommentCancel() {
      this.$bvModal.hide("new-incidence-comment-modal");
    },
    save() {
      if (!this.incidence) return;
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("incidence/updateIncidence", {
          uuid: this.incidence.uuid,
          title: this.title,
          status: this.status,
          booking: this.booking,
          tags: this.serializedTags,
          createdAt: this.createDate,
          updatedAt: formatDateObjectToDatabaseDateTime(new Date()),
        })
        .then(() => {
          this.editMode = false;
          notifySuccess(
            "Incidència actualitzada",
            "La incidència ha estat actualitzada correctament"
          );
          this.$emit("new-incidence-close");
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar l'estat de la incidència"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

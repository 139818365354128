<template>
  <div id="close-incidence-form" class="pb-1">
    <b-row class="mb-1">
      <!-- TITOL -->
      <b-col cols="12">
        <b-form-group :label="$t('Títol')" label-for="title">
          <b>{{ incidence.title || $t("No disponible") }}</b>
        </b-form-group>
      </b-col>

      <!-- COMENTARI -->
      <b-col cols="12">
        <b-form-group :label="$t('Comentari')" label-for="comment">
          <quill-editor
            id="comment"
            v-model="comment"
            :options="editorOption"
            class="w-100"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- BUTTON -->
    <b-row class="d-flex justify-content-end">
      <b-col cols="12" md="auto" class="mb-1 mb-md-0">
        <b-button variant="secondary" block @click="close">
          Cancel·la
        </b-button>
      </b-col>
      <b-col cols="12" md="auto">
        <b-button
          variant="primary"
          :disabled="!comment"
          block
          @click="closeIncidence"
        >
          Tanca incidència
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormGroup } from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { formatDateObjectToDatabaseDateTime } from "@/utils/formatters";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    quillEditor,
    BFormGroup,
  },
  props: {
    incidence: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      comment: null,
      editorOption: {
        placeholder: this.$t("Explica com s'ha resolt l'incidència"),
        modules: {
          toolbar: false,
        },
      },
    };
  },
  computed: {
    loggedUserEmail() {
      return this.$store.getters["auth/loggedUserEmail"];
    },
    sanitizedComment() {
      if (!this.comment) return null;
      return this.comment.replace(/<p><br><\/p>/g, "").trim();
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.comment = null;
    },
    closeIncidence() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("incidence/addComment", {
          incidence: this.incidence["@id"],
          author: this.loggedUserEmail,
          content: this.sanitizedComment,
          isSolution: true,
          postedAt: formatDateObjectToDatabaseDateTime(new Date()),
        })
        .then(() => {
          this.$store
            .dispatch("incidence/updateIncidence", {
              uuid: this.incidence.uuid,
              status: "CLOSED",
              updatedAt: formatDateObjectToDatabaseDateTime(new Date()),
            })
            .then(() => {
              this.$emit("close-incidence-close");
              notifySuccess(
                "Incidència tancada",
                "La incidència ha estat tancada correctament"
              );
            })
            .catch(() => {
              notifyError(
                "Error",
                "Hi ha hagut un error al intentar tancar la incidència"
              );
            })
            .finally(() => this.$store.dispatch("app/setLoading", false));
        })
        .catch(() => {
          this.$store.dispatch("app/setLoading", false);
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar tancar la incidència"
          );
        });
    },
    close() {
      this.$emit("close-incidence-close");
    },
  },
};
</script>

<template>
  <div class="bg-light p-1 border">
    <div
      class="d-flex justify-content-between align-items-center border-bottom pb-1"
    >
      <span class="d-flex align-items-center">
        <b>
          {{ getCommentDate(comment) }}
        </b>
        <small>
          <b-badge v-if="comment.isSolution" class="ml-50 mt-25">
            Solució
          </b-badge>
        </small>
      </span>
      <b-button
        v-if="canDelete"
        size="sm"
        variant="danger"
        class="btn-icon ml-50 p-25"
        @click="onDeleteCommentButtonClicked(comment)"
      >
        <feather-icon icon="Trash2Icon" size="13" />
      </b-button>
    </div>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="mt-50 mb-1" v-html="comment.content" />

    <!-- COMMENT pictures -->
    <b-row v-if="commentPictures.length" class="d-flex flex-wrap">
      <b-col
        v-for="(picture, pictureIndex) in commentPictures"
        :key="`incidence-comment-${comment.uuid}-picture-${pictureIndex}`"
        cols="6"
        md="4"
        lg="3"
        xl="2"
        class="mb-50"
      >
        <b-card
          overlay
          :img-src="picture.thumbnailUrl"
          img-alt="Incidence comment image"
          class="mb-0 cursor-pointer"
          @click="showLightbox(pictureIndex)"
        >
          <div class="d-flex justify-content-end">
            <b-button
              v-if="canDelete"
              size="sm"
              variant="danger"
              class="btn-icon ml-50 p-25"
              @click.stop="askDeleteCommentPicture(comment, picture)"
            >
              <feather-icon icon="Trash2Icon" size="13" />
            </b-button>
          </div>
        </b-card>
      </b-col>

      <vue-easy-lightbox
        :visible="lightboxVisible"
        :imgs="commentPicturesUrls"
        :index="lightboxIndex"
        @hide="hideLightbox"
      />
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BBadge } from "bootstrap-vue";
import { formatDateStringToDate } from "@/utils/formatters";
import { notifyError, notifySuccess } from "@/utils/methods";
// https://github.com/XiongAmao/vue-easy-lightbox/tree/vue2.x
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    VueEasyLightbox,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lightboxVisible: false,
      lightboxIndex: 0,
    };
  },
  computed: {
    commentPictures() {
      return this.comment?.pictures || [];
    },
    commentPicturesUrls() {
      if (!this.commentPictures.length) return [];
      return this.commentPictures.map((picture) => picture.url);
    },
  },
  methods: {
    getCommentDate(comment) {
      if (!comment?.postedAt) return null;
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(
        comment.postedAt,
        this.$i18n.locale,
        formatting
      );
    },
    onDeleteCommentButtonClicked(comment) {
      this.selectedComment = comment;
      this.$swal({
        title: "Eliminar comentari",
        text: "Estàs a punt d'eliminar aquest comentari, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteComment();
      });
    },
    deleteComment() {
      this.loading = true;
      this.$store
        .dispatch("incidence/deleteComment", this.selectedComment)
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar el comentari"
          )
        )
        .finally(() => {
          this.loading = false;
          notifySuccess(
            "Comentari eliminat",
            "El comentari ha estat eliminat correctament"
          );
        });
    },
    askDeleteCommentPicture(faq, picture) {
      this.$swal({
        title: "Eliminar foto",
        text: `Estàs a punt d'eliminar aquesta foto del comentari, estàs segur/a?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCommentPicture(faq, picture);
        }
      });
    },
    deleteCommentPicture(faq, picture) {
      this.loading = true;
      this.$store
        .dispatch("accommodation/deleteAccommodationFaqPicture", {
          faqUuid: faq.uuid,
          pictureUuid: picture.uuid,
        })
        .then(() =>
          notifySuccess(
            "Foto eliminada",
            "La foto ha estat eliminada correctament de la FAQ"
          )
        )
        .catch(() =>
          notifyError(
            "Foto no eliminada",
            "Hi ha hagut un error al intentar elminar la foto de la FAQ"
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    showLightbox(index) {
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    hideLightbox() {
      this.lightboxVisible = false;
      this.lightboxIndex = 0;
    },
  },
};
</script>

<template>
  <b-overlay
    :show="loading"
    variant="white"
    :opacity="0.8"
    id="new-incidence-comment-form"
  >
    <!-- COMMENT -->
    <b-row class="mb-1">
      <b-col cols="12">
        <h5>Comentari</h5>
        <quill-editor v-model="comment" :options="editorOption" class="w-100" />
      </b-col>
    </b-row>

    <!-- SOLUTION -->
    <b-row class="mb-1">
      <b-col cols="12">
        <h5>Solució</h5>
        <b-form-checkbox v-model="isSolution" switch>
          Aquesta és la solució a la incidència
        </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- PICTURES -->
    <b-row>
      <b-col cols="12">
        <h5>
          Fotos
          <small>(opcional)</small>
        </h5>
        <b-form-file
          v-if="previewPictures"
          id="wildcard"
          accept="image/*"
          multiple
          @change="handleImages($event)"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="previewPictures && previewPictures.length"
      class="d-flex flex-wrap mt-2"
    >
      <b-col
        v-for="(picture, index) in previewPictures"
        :key="`preview-picture-${index}`"
        cols="12"
        md="6"
        lg="3"
      >
        <b-card
          overlay
          :img-src="picture"
          img-alt="Incidence comment image"
          class="mb-50"
        >
          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="danger"
              class="btn-icon ml-50 p-25"
              @click.stop="deletePreviewImage(index)"
            >
              <feather-icon icon="Trash2Icon" size="13" />
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-end mt-2">
      <b-col cols="6" md="auto">
        <b-button block @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" md="auto">
        <b-button block variant="primary" :disabled="!comment" @click="save">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  BButton,
  BFormCheckbox,
  BFormFile,
  BCard,
} from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { formatDateObjectToDatabaseDateTime } from "@/utils/formatters";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    quillEditor,
    BButton,
    BFormCheckbox,
    BFormFile,
    BCard,
  },
  props: {
    incidence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      comment: null,
      previewPictures: [],
      isSolution: false,
      editorOption: {
        placeholder: this.$t("Afegeix un comentari"),
        modules: {
          toolbar: false,
        },
      },
    };
  },
  computed: {
    loggedUserEmail() {
      return this.$store.getters["auth/loggedUserEmail"];
    },
    sanitizedComment() {
      if (!this.comment) return null;
      return this.comment.replace(/<p><br><\/p>/g, "").trim();
    },
  },
  methods: {
    save() {
      this.loading = true;
      this.$store
        .dispatch("incidence/addComment", {
          incidence: this.incidence["@id"],
          author: this.loggedUserEmail,
          content: this.sanitizedComment,
          isSolution: this.isSolution,
          postedAt: formatDateObjectToDatabaseDateTime(new Date()),
        })
        .then(() => {
          this.comment = null;
          this.isSolution = false;
          this.$emit("added");
          notifySuccess(
            "Comentari afegit",
            "El comentari ha estat afegit correctament a la incidència"
          );
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar afegir un comentari a la incidència"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit("cancel");
    },
    handleImages(event) {
      this.previewPictures = [];
      event.target.files.forEach((file) => {
        this.createBase64Image(file);
      }, this);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.previewPictures.push(event.target.result);
      };
      reader.readAsDataURL(fileObject);
    },
    deletePreviewImage(index) {
      this.previewPictures.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
#new-incidence-comment-form {
  .quill-editor {
    .ql-editor {
      p {
        margin-top: 0 !important;
        margin-bottom: 1rem !important;
      }
    }
  }
}
</style>
